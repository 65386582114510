// Deep Link offer
.SingleOffer {
  max-width: 25%;
  margin: 0 auto;
}
a.viewMore {
  margin: 2em 0;
  font-size: 1.5em;
}

// List of offers
.offerContent {
  width: 95%;
  max-width: 1224px;
  margin: 0 auto;
  h2.sectionTitle {
    font-size: 2rem;
    text-align: left;
    padding-bottom: 0.5em;
  }
  .OfferList {
    //display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(200px, auto));
    // grid-template-rows: auto;
    // grid-gap: 10px;
    // justify-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    max-width: 1224px;
    margin: 0 auto;
  }
}
// Featured Styles
.FeaturedOfferList {
  padding-bottom: 4em;
  .card {
    border: none;
    max-width: initial;
    margin: 0 auto;
  }
  .logo-img {
    padding-bottom: 16px;
    width: 50%;
    max-height: 200px;
    object-fit: contain;
  }
  .featured-block {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: relative;
    a {
      flex: 50%;
      .offer-img {
        max-width: 450px;
        max-height: 450px;
        object-fit: inherit;
        padding-bottom: 1em;
      }
    }
    div {
      flex: 50%;
      .content {
        .card-title {
          font-size: 50px;
          margin-bottom: 8px;
          -webkit-line-clamp: inherit;
          max-height: inherit;
        }
        .description.card-text {
          font-size: 18px;
          -webkit-line-clamp: inherit;
          max-height: inherit;
          min-height: inherit;
        }
      }
      .offer-btn {
        width: 30%;
      }
      .moreInfo {
        width: 70%;
        .overlay {
          width: 70%;
        }
        .content {
          width: 70%;
          justify-content: space-around;
        }
      }
    }
  }
}
// offer item
.offer {
  position: relative;
  width: 23%;
  margin: 0 1% 2%;
  .content {
    font-size: 0.8em;
    padding: 1rem 1rem 0.5rem;
  }
  .offer-img {
    max-width: 100%;
    object-fit: contain;
    width: auto;
    padding: 1em 1em 0;
  }
  .card-title,
  .card-text {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    padding: 0;
  }
  .card-title {
    -webkit-line-clamp: 2;
    font-weight: 400;
    margin-bottom: 0.5em;
    max-height: 2.4em;
    min-height: 2.4em;
    line-height: 1.1em;
  }
  .description {
    -webkit-line-clamp: 2;
    font-size: 1.1em;
    font-weight: 400;
    margin: 0;
    max-height: 2.3em; //IE11 fix includes font size and line height
    min-height: 2.3em;
    line-height: 1.2em;
  }
  .expiration {
    font-size: 0.9em;
  }
  .offer-btn {
    width: 80%;
    margin: 0 auto 1rem;
    border-radius: 7px;
  }
}
@media screen and(max-width:960px) {
  .offerContent {
    h2.sectionTitle {
      font-size: 1.75rem;
    }
  }
  .offer {
    width: 31%;
    margin: 0 1% 2%;
  }
}
@media screen and (max-width:767px) {
  .offer {
    width: 48%;
  }
}
@media screen and(max-width:500px) {
  .offer {
    width:100%;
    margin: 0 auto 3%;
    .card-title {
      -webkit-line-clamp: initial;
      min-height: initial;
    }
  }
}
// IPAD
@media only screen and (max-width: 960px) and (min-width: 768px) {
  .FeaturedOfferList {
    .featured-block {
      a {
        .offer-img {
          width: 100%;
        }
      }
      div {
        .offer-btn {
          width: 50%;
        }
      }
    }
  }
}
// MOBILE
@media screen and(max-width:767px) {
  .FeaturedOfferList {
    .card {
      max-width: 250px;
    }
    .logo-img {
      width: 100%;
    }
    .banner.card-text {
      width: 100%;
    }
    .featured-block {
      display: block;
      a {
        .offer-img {
          width: 100%;
          padding-bottom: 0;
        }
      }
      div {
        .content {
          .card-title {
            font-size: 30px;
          }
        }
        .offer-btn {
          width: 80%;
        }
        .moreInfo {
          width: 100%;
          .overlay {
            width: 100%;
          }
          .content {
            width: 100%;
          }
        }
      }
    }
  }
}


// retailer styles
// publix

// family dollar

// Kroger Family
.kroger {
  // put kroger specific styling here
  .card {
    position: relative;
    display: flex;
    background: #fff;
    border: 0.1em solid transparent;
    flex-basis: 360px;
    min-width: 0;
    margin: 8px;
    flex-grow: 1!important;
    align-items: stretch!important;
  }

  .featured-block {
    border: 1px solid #dfe1e1;
    border-radius: 0.5rem;
    background: #fff;
    width: 100%;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: row;
    // justify-content: flex-start;
    // align-items: flex-start;
    align-content: stretch;
    position: relative;
    box-sizing: border-box;
    height: 100%;
    border: none!important;
    align-items: stretch;
    font-family: -Nunito,-apple-system,BlinkMacSystemFont,Roboto,Segoe UI,system-ui,Helvetica Neue,Ubuntu,sans-serif;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,.14118),0 1px 10px 0 rgba(0,0,0,.12157),0 2px 4px -1px rgba(0,0,0,.2)!important;
  }

  .featured-block>a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .featured-block>div {
    width: 100%;
  }

  .offer .offer-img {
    width: 190px;
    max-width: 190px;
    max-height: 190px;
    object-fit: contain;
    padding: 1em 1em;
  }

  .offer .content {
    width: 100%;
    display: inline-block;
    min-height: 3rem;
    vertical-align: top;
    padding: 8px;
    text-align: left;
  }

  .offer .card-title {
    line-height: 2.25;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    overflow: hidden;
    color: #1d1e1f!important;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 700;
    font-size: 2rem;
    font-family: Nunito,-apple-system,BlinkMacSystemFont,Roboto,Segoe UI,system-ui,Helvetica Neue,Ubuntu,sans-serif;
  }

  .offer .description { 
    font-weight: 400;
    overflow: hidden;
    line-height: 1.3em;
    min-height: 1.3em;
    color: #1d1e1f!important;
  }

  .moreLink {
    color: #4471b7 !important;
  }

  .moreInfo, .overlay {
    background-color:#4471b7 !important;
  }

  .offer .expiration {
    color: #5d5e60!important;
  }

  .offer .offer-btn {
    width: calc(90% - 5px);
    cursor: pointer;
    padding: 0 5px;
    height: 32px;
    color: #fff;
    border-radius: 1rem;
    border-style: none;
    background-color: #4471b7 !important;
    border-color: #4471b7 !important;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,.14118),0 1px 10px 0 rgba(0,0,0,.12157),0 2px 4px -1px rgba(0,0,0,.2)!important;
  }

  @media screen and (max-width:1200px) {
    .offer .card-title {
      line-height: 1;
      min-height: 2px;
      overflow: hidden!important;
      text-overflow: ellipsis!important;
      overflow: hidden;
      color: #1d1e1f!important;
      padding: 0;
      margin-bottom: 8px;
      margin-top: 0;
      font-weight: 700;
      font-size: 2rem;
      font-family: Nunito,-apple-system,BlinkMacSystemFont,Roboto,Segoe UI,system-ui,Helvetica Neue,Ubuntu,sans-serif;
    }

    .offer .offer-img {
      width: 170px;
      max-width: 170px;
      max-height: 170px;
      object-fit: contain; 
      padding: 1em 1em;
    }
  }
}