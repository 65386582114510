.content-page {
    width:95%;
    max-width: 1224px;
    margin: 3em auto;
    
    h2.sectionTitle {
      font-size: 2rem;
      text-align: left;
      padding-bottom: 0.5em;
    }
  
    .cart-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      max-width: 1224px;
      margin: 0 auto;
    }
  
    .cart-card { 
      // max-width: 232.5px;
      position: relative;
      width: 23%;
      margin: 0 1% 2%;
      .card-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: flex-end;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        box-shadow: 0px 3px 10px 0 rgba(110,118,152,0.25);
        border-radius: 10px;
        padding: .15em;
        height: 100%;
  
        img { 
          width: 100px;
          margin: 1em auto 0.5em;
        }
  
        .card-title {
          font-weight: 600;
          padding: 0 0.5em;
          margin: 1em 0 1.5em;
          text-transform: uppercase;
        }
      
        .cart-btn {
          width: 80%;
          margin: 0 auto 1rem;
          border-radius: 7px;
          background: #88bc42;
          a {
            color: #fff;
          }
        }
      }
    }
  }
  @media screen and (max-width: 960px) {
    .content-page {
      h2.sectionTitle {
        font-size: 1.75rem;
      }
      .cart-card {
        width: 31%;
        margin: 0 1% 2%;
      }
    }
    
  }
  @media screen and (max-width:767px) {
    .content-page {
      .cart-card {
        width: 48%;
      }
    }
  }
  @media screen and(max-width:500px) {
    .content-page {
      .cart-card {
        width:100%;
        margin: 0 auto 3%;
      }
    }
  }