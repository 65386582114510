.retailerContent {
    padding: 3em 0;
    .changeRetailer {
        background: #eeeeee;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        padding: 1em 2em;
        width: 98%;
        margin: 0 auto 3em;
        div {
            width: 50%;
            align-self: center;
            p {
                margin: 0;
            }
        }
        .selected {
            align-self: center;
            text-align: left;
        }
        .change {
            text-align: right;
            a {
               color: #000;
               font-weight: 700;
               font-size: 1.1em;
            }
            
        }
    }
}